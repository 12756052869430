.footer{
    padding-top: 8rem;
    padding-bottom: 8rem;
    border-top: 1px solid #dcdcdc;
    font-size: 1.6rem;
    line-height: 2rem;

    @media (max-width: $md){
        padding: 4rem 0;
    }

    .container{
        &__bx{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2rem;

            @media(max-width: $md){
                grid-template-columns: repeat(1, 1fr);
                gap: 0; 
            }
        }
    }

    &__cl{

        &--left{
            a{
                text-decoration: none;
                color: $black
            }
        }

        &--center{
            display: flex;
            justify-content: center;
            padding-top: 6rem;

            @media(max-width: $md){
                justify-content: flex-start;
                padding-top: 4rem;
            }
        }

        &--right{
            display: flex;
            justify-content: flex-end;
            padding-top: 6rem;
            text-align: right;

            @media(max-width: $md){
                justify-content: flex-start;
                text-align: left;
                padding-top: 4rem;
            }

            .menu{
                a{
                    text-decoration: none;    
                    color: $black;
                }
            }
        }

    }

    .logo{
        img{
            max-width: 24.3rem;
        }
    }

    &__add{

        .footer{
            &__icos {
                padding-top: 1.5rem;
                background-position: center left;
                background-repeat: no-repeat;
                padding-left: 4rem;
                background-size: 2.4rem;
        
                &:nth-child(1){
                    background-image: url('../imgs/icons/icon-ubicacion.svg');
                }
                &:nth-child(2){
                    background-image: url('../imgs/icons/icon-contacto.svg');
                }
                &:nth-child(3){
                    background-image: url('../imgs/icons/icon-correo.svg');
                }
            }
        }
    }

    
}


.rrss{
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-weight: 600;

    &__list{
        display: flex;
        gap: 2rem;
        margin-top: 2rem;
        justify-content: center;
        
        img{
            max-width: 3.2rem;    
        }
    }

}