.form-group {
    display: flex;
    flex-flow: column;
    width: 100%;

    &--inline {
        display: inline-flex;
        margin-bottom: 2rem;
        flex-flow: row;
        align-items: center;
        justify-content: flex-start;

        .form-label{
            width: auto;
            display: inline-flex;
            margin-right: 2.8rem;
        }

        .form-control{
            width: 8rem;
            display: inline-flex;
            margin: 0;
            background-color: #f4f4f4;
            text-align: center;
        }
    }
}

.form-label {
    display: flex;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.form-p{
    font-weight: 500;
    margin-bottom: 2rem;

    &--result{
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
    }
}

.form-control{
    width: 100%;
    border: 0.25px solid $secondary;
    border-radius: 0.4rem;
    padding: 0.5rem 1rem;
    font-family: $font;
    color: $secondary;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 2rem;
    opacity: 1;
    box-sizing: border-box;

    &::placeholder{
        font-family: $font;
        color: $secondary; 
        font-size: 1.6rem;
        line-height: 2.4rem;
        opacity: 0.6;
    }
    
}

.form-group-check {
    display: block;
    margin-bottom: 2rem;
    position: relative;

    &--cols{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 2rem;
    }

    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        cursor: pointer;
        position: absolute;
        z-index: -1;
        width: 1px;
        height: 1px;
        top: 1.8rem;
        left: 0.1rem;
        margin: 0;

        &:checked + label:after {
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            left: 9px;
            width: 6px;
            height: 14px;
            border: solid $black;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
        }
    }

    label {
        position: relative;
        cursor: pointer;
        padding-left: 4.2rem;
        display: flow;
        padding-top: 0.2rem;
        min-height: 2.3rem;
        
        span{
            font-size: 1.2rem;
            line-height: 1.4rem;
        }

        a{
            color: #393939;
            text-decoration: none;
        }

        &:before {
            content:'';
            -webkit-appearance: none;
            background-color: transparent;
            border: 0.1rem solid $black;
            box-shadow: none;
            padding: 0;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 5px;
            box-sizing: border-box;
            width: 2.3rem;
            height: 2.3rem;
            background-color: #f4f4f4;
            border-radius: 0.2rem;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.alert {
    background-color: #f4f4f4;
    border-radius: 0.2rem;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-style: italic;
    margin-bottom: 2rem;
}