@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap');

$font: 'Raleway', sans-serif;
$font2: 'Maven Pro', sans-serif;

//colors
$black: #000;
$white: #fff;
$primary: #262833;
$secondary: #393939;
$yellow:#fcea10;
//media queries
$sm: 575.98px;
$md: 767.98px;
$lg: 991.98px;
$xl: 1199.98px;
$xxl: 1399.98px;