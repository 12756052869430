.btn {
    background: linear-gradient(175.61deg, #FCEA10 -0.64%, #2FAC66 29.82%, #1D7AB8 117.5%);
    border: 0;
    border-radius: 0.5rem;
    height: 4rem;
    box-sizing: border-box;
    color: white;
    font-family: $font;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 300ms linear;

    &:hover{
        background: linear-gradient(180deg, #FCEA10 -0.64%, #2FAC66 29.82%, #1D7AB8 117.5%); 
    }
}