.header{
  padding: 2rem 0 0.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $primary;
  z-index: 10;
  color: $white;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2rem;
    background: $primary;
    clip-path: polygon(0 0, 100% 0, 100% 30%, 0 100%);

  }

  .container{

    &__bx{
      flex-flow: row;
      justify-content: space-between;
    }
  }

  &__col{
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  .logo{
    img{
      max-width: 20rem;
      width: 100%;
    }
  }

  &.js-sticky{
  }

  .menu{
    ul{
      display: flex;
      flex-flow: row;
      li{
        padding: 0 1rem;
        position: relative;
  
        &.has-child{
         
          > a{
  
            &:after{
              content: '';
              background-image: url('../imgs/chevron-down-solid.svg');
              background-repeat: no-repeat;
              background-size: 1.4rem;
              background-position: right center;
              display: inline-block;
              width: 20px;
              height: 11px;
            }
            
          }
  
          &:hover{
            > .submenu{
              display: flex;
            }
  
            > a
              &:after{
                transform: rotate(180deg);
                transition: 0.4s ease;
              }
          }
        }
  
        &.selected{
          a{
            font-weight: 700;
            color: $yellow;
          }
        }
  
        a{
          text-decoration: none;
          display: block;
          color: $white;
        }
  
        ul.submenu {
          flex-flow: column;
          display: none;
          position: absolute;
          left: 0;
          top: 100%;
          background-color: $white;
          padding: 0 1rem;
          width: 13rem;
  
          li{
            padding: 0 1rem;
            width: 100%;
            display: block;
  
            &.has-child{
              > a{
                &:after{
                  content: '';
                  background-image: url('../imgs/chevron-right-solid.svg');
                  background-repeat: no-repeat;
                  background-size: 0.8rem;
                  background-position: right center;
                  display: inline-block;
                  width: 20px;
                  height: 11px;
                }
              }
            }
  
            ul.submenu {
              position: absolute;
              width: 100%;
              left: 100%;
              top: 0;
            }
          }
        }
      }
    }
  
    @media(max-width: $lg){
      display: none;
    }
  }
  
}


.m-hamb{
  display: none;
  width: 100%;
  height: 100vh;
  z-index: 9;
  position: fixed;
  background-color: white;
  top: 0;
  padding-top: 8rem;

  &.js-open{

    @media(max-width: $lg){
      display: block;
    }

    .menu{
      @media(max-width: $lg){
        display: block;
      }
    }
  }

  .menu{
    ul{
      flex-flow: column; 
      li{
        ul.submenu{
          position: relative;
          left: auto;
          top: auto;
          padding: 0;
          width: auto;

          li{
            ul.submenu{
              position: relative;
              left: auto;
              top: auto;
            }
          }
        }
      }
    }  
  }

}

.lang-sel{
  text-transform: uppercase;
  position: relative;

  &__current{
    padding-right: 1.5rem;
    cursor: pointer;

    &::after{
      content: '';
      background-image: url('../imgs/icon-lang-down.svg');
      width: 1rem;
      height: 1rem;
      background-size: 1rem;
      background-repeat: no-repeat;
      background-position: center right;
      display: block;
      position: absolute;
      right: 0;
      top: 0.4rem;
      transition-duration: 0.3s;
      transition-property: transform;
    }
    &.js-show{
      &::after{
        transform: rotate(180deg);
      }
    }
  }  

  &__toggle{
    display: none;  
    position: absolute;
    right: 0;
    background-color: $white;
    flex-flow: column;
    box-shadow: 0 0 1rem #ccc;
    border-radius: 0.3rem;
    padding: 1rem 2rem;
    margin-top: 1rem;

    &::after {
      content: " ";
      position: absolute;
      right: 1.5rem;
      top: -1rem;
      border-top: none;
      border-right: 1rem solid transparent;
      border-left: 1rem solid transparent;
      border-bottom: 1rem solid $white;
    }

    &.js-show{
      display: flex;
    }
  }

  &__item{
    color: $black;
    text-decoration: none;
  }
}

.m-toggle{
  display: none;
  width: 4rem;
  height: 3rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  @media (max-width: $lg) {
    display: block; 
  }

  & span{
      display: block;
      position: absolute;
      height: 0.2rem;
      width: 100%;
      background: $black;
      border-radius: 0;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

      &:nth-child(1) {
          top: 0;
      }

      &:nth-child(2){
          top: 1rem;
      }

      &:nth-child(3){
          top: 1rem;
      }

      &:nth-child(4) {
          top: 2rem;
      }
  }

  &.js-open{
    & span{
        &:nth-child(1) {
            top: 1rem;
            width: 0%;
            left: 50%;
        }

        &:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        } 

        &:nth-child(4) {
            top: 1rem;
            width: 0%;
            left: 50%;
        }
    }
  }
}
