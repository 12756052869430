/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400..900&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  /* 1rem = 10px */
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

.container {
  max-width: 116rem;
  width: 100%;
  margin: 0 auto;
  padding: 0rem;
}
.container__bx {
  padding: 0 2rem;
  display: flex;
  flex-flow: column;
}
@media (max-width: 767.98px) {
  .container__bx {
    flex-flow: column;
  }
}

a, button, input {
  outline: none;
}

strong {
  font-weight: 600;
}

.page-content {
  margin-top: 12rem;
}
.page-content p {
  margin-bottom: 1.6rem;
}

.h2-title {
  position: relative;
  text-align: center;
  margin-bottom: 3rem;
}
@media (max-width: 767.98px) {
  .h2-title {
    padding-bottom: 1rem;
  }
}
.h2-title::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 1;
  background-color: #2fac66;
}
@media (max-width: 767.98px) {
  .h2-title::after {
    top: inherit;
    bottom: 0;
    left: 50%;
    margin-left: -4rem;
    width: 8rem;
  }
}
.h2-title .h2 {
  position: relative;
  display: inline-block;
  padding-right: 4rem;
  padding-left: 4rem;
  margin-right: 14rem;
  margin-left: 14rem;
  z-index: 2;
  font-size: 38.4px;
  line-height: 44px;
  background-color: #fff;
}
@media (max-width: 767.98px) {
  .h2-title .h2 {
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
    font-size: 22.4px;
    line-height: 27.2px;
  }
}

.mdl-contact {
  padding: 0;
}
.mdl-contact .step {
  padding-top: 10rem;
}
@media (max-width: 767.98px) {
  .mdl-contact .step {
    padding-top: 5rem;
  }
}
.mdl-contact__feat {
  padding: 5rem 0;
  background-color: #f4f4f4;
}
@media (max-width: 767.98px) {
  .mdl-contact__feat {
    padding: 5rem 0 3rem;
  }
}
.mdl-contact__feat .h2-title .h2 {
  background-color: #f4f4f4;
}
.mdl-contact form {
  padding: 5rem 0;
  max-width: 50rem;
  margin: 0 auto;
  width: 100%;
}
@media (max-width: 767.98px) {
  .mdl-contact form {
    padding-top: 0rem;
  }
}
.mdl-contact p.small {
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 2rem;
}
.mdl-contact ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767.98px) {
  .mdl-contact ul {
    grid-template-columns: repeat(1, 1fr);
  }
}
.mdl-contact ul li {
  padding-left: 2rem;
  position: relative;
  margin-bottom: 2.3rem;
}
.mdl-contact ul li::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background: #2EAA68;
  display: block;
  border-radius: 5rem;
  position: absolute;
  left: 0;
  top: 4px;
}

.mdl-feedback__icon img {
  max-width: 11.8rem;
}
.mdl-feedback__txt {
  max-width: 78rem;
}
@media (max-width: 767.98px) {
  .mdl-feedback__txt {
    max-width: 42rem;
  }
}
.mdl-feedback__txt h2 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 4.7rem;
  text-align: center;
  color: #fff;
  font-family: "Maven Pro", sans-serif;
  margin-bottom: 4rem;
}
@media (max-width: 767.98px) {
  .mdl-feedback__txt h2 {
    font-size: 3rem;
    line-height: 3.6rem;
  }
}
.mdl-feedback__txt p {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3rem;
  text-align: center;
  color: #fff;
}
@media (max-width: 767.98px) {
  .mdl-feedback__txt p {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}
.mdl-feedback__opt {
  padding: 10rem 2rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media (max-width: 767.98px) {
  .mdl-feedback__opt {
    padding: 6rem 2rem;
  }
}
.mdl-feedback__opt::after {
  content: "";
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  width: 100%;
  height: 2rem;
  background: #fff;
  clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%);
}
.mdl-feedback__opt--ko {
  background-color: #E94F4F;
}
.mdl-feedback__opt--ok {
  background: #2EAA68;
}
.mdl-feedback__opt--intro {
  height: 65.9rem;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-image: url("../imgs/home/hero.jpg");
}
@media (max-width: 767.98px) {
  .mdl-feedback__opt--intro {
    background-image: url("../imgs/home/hero-bg-mob.jpg");
    padding: 4rem 0rem;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    height: 60rem;
  }
}
.mdl-feedback__opt--intro .mdl-feedback__txt {
  max-width: 112rem;
  width: 100%;
  padding: 0 2rem;
  text-align: right;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
  box-sizing: border-box;
}
@media (max-width: 767.98px) {
  .mdl-feedback__opt--intro .mdl-feedback__txt {
    max-width: 40rem;
    align-items: flex-start;
  }
}
.mdl-feedback__opt--intro .mdl-feedback__txt h1 {
  font-size: 3.4rem;
  font-weight: 400;
  line-height: 4rem;
  text-align: left;
  color: #fff;
  margin-bottom: 3rem;
  max-width: 50rem;
}
.mdl-feedback__opt--intro .mdl-feedback__txt h1 strong {
  font-weight: 600;
}
.mdl-feedback__opt--intro .mdl-feedback__txt img {
  max-width: 3.2rem;
}

.pop {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-left: 1rem;
}
.pop img {
  display: block;
}

.fancy.fancybox__content {
  padding: 5rem;
}
@media (max-width: 767.98px) {
  .fancy.fancybox__content {
    padding: 2rem;
  }
}
.fancy.fancybox__content .h2-title .h2 {
  margin-right: 10rem;
  margin-left: 10rem;
}
@media (max-width: 767.98px) {
  .fancy.fancybox__content .h2-title .h2 {
    margin-left: 0;
    margin-right: 0;
  }
}
.fancy.fancybox__content p {
  text-align: center;
  margin-bottom: 3rem;
}
.fancy.fancybox__content img {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
}

.header {
  padding: 2rem 0 0.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #262833;
  z-index: 10;
  color: #fff;
  position: relative;
}
.header::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2rem;
  background: #262833;
  clip-path: polygon(0 0, 100% 0, 100% 30%, 0 100%);
}
.header .container__bx {
  flex-flow: row;
  justify-content: space-between;
}
.header__col {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.header .logo img {
  max-width: 20rem;
  width: 100%;
}
.header .menu ul {
  display: flex;
  flex-flow: row;
}
.header .menu ul li {
  padding: 0 1rem;
  position: relative;
}
.header .menu ul li.has-child > a:after {
  content: "";
  background-image: url("../imgs/chevron-down-solid.svg");
  background-repeat: no-repeat;
  background-size: 1.4rem;
  background-position: right center;
  display: inline-block;
  width: 20px;
  height: 11px;
}
.header .menu ul li.has-child:hover > .submenu {
  display: flex;
}
> a .header .menu ul li.has-child:hover:after {
  transform: rotate(180deg);
  transition: 0.4s ease;
}
.header .menu ul li.selected a {
  font-weight: 700;
  color: #fcea10;
}
.header .menu ul li a {
  text-decoration: none;
  display: block;
  color: #fff;
}
.header .menu ul li ul.submenu {
  flex-flow: column;
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #fff;
  padding: 0 1rem;
  width: 13rem;
}
.header .menu ul li ul.submenu li {
  padding: 0 1rem;
  width: 100%;
  display: block;
}
.header .menu ul li ul.submenu li.has-child > a:after {
  content: "";
  background-image: url("../imgs/chevron-right-solid.svg");
  background-repeat: no-repeat;
  background-size: 0.8rem;
  background-position: right center;
  display: inline-block;
  width: 20px;
  height: 11px;
}
.header .menu ul li ul.submenu li ul.submenu {
  position: absolute;
  width: 100%;
  left: 100%;
  top: 0;
}
@media (max-width: 991.98px) {
  .header .menu {
    display: none;
  }
}

.m-hamb {
  display: none;
  width: 100%;
  height: 100vh;
  z-index: 9;
  position: fixed;
  background-color: white;
  top: 0;
  padding-top: 8rem;
}
@media (max-width: 991.98px) {
  .m-hamb.js-open {
    display: block;
  }
}
@media (max-width: 991.98px) {
  .m-hamb.js-open .menu {
    display: block;
  }
}
.m-hamb .menu ul {
  flex-flow: column;
}
.m-hamb .menu ul li ul.submenu {
  position: relative;
  left: auto;
  top: auto;
  padding: 0;
  width: auto;
}
.m-hamb .menu ul li ul.submenu li ul.submenu {
  position: relative;
  left: auto;
  top: auto;
}

.lang-sel {
  text-transform: uppercase;
  position: relative;
}
.lang-sel__current {
  padding-right: 1.5rem;
  cursor: pointer;
}
.lang-sel__current::after {
  content: "";
  background-image: url("../imgs/icon-lang-down.svg");
  width: 1rem;
  height: 1rem;
  background-size: 1rem;
  background-repeat: no-repeat;
  background-position: center right;
  display: block;
  position: absolute;
  right: 0;
  top: 0.4rem;
  transition-duration: 0.3s;
  transition-property: transform;
}
.lang-sel__current.js-show::after {
  transform: rotate(180deg);
}
.lang-sel__toggle {
  display: none;
  position: absolute;
  right: 0;
  background-color: #fff;
  flex-flow: column;
  box-shadow: 0 0 1rem #ccc;
  border-radius: 0.3rem;
  padding: 1rem 2rem;
  margin-top: 1rem;
}
.lang-sel__toggle::after {
  content: " ";
  position: absolute;
  right: 1.5rem;
  top: -1rem;
  border-top: none;
  border-right: 1rem solid transparent;
  border-left: 1rem solid transparent;
  border-bottom: 1rem solid #fff;
}
.lang-sel__toggle.js-show {
  display: flex;
}
.lang-sel__item {
  color: #000;
  text-decoration: none;
}

.m-toggle {
  display: none;
  width: 4rem;
  height: 3rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
@media (max-width: 991.98px) {
  .m-toggle {
    display: block;
  }
}
.m-toggle span {
  display: block;
  position: absolute;
  height: 0.2rem;
  width: 100%;
  background: #000;
  border-radius: 0;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.m-toggle span:nth-child(1) {
  top: 0;
}
.m-toggle span:nth-child(2) {
  top: 1rem;
}
.m-toggle span:nth-child(3) {
  top: 1rem;
}
.m-toggle span:nth-child(4) {
  top: 2rem;
}
.m-toggle.js-open span:nth-child(1) {
  top: 1rem;
  width: 0%;
  left: 50%;
}
.m-toggle.js-open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.m-toggle.js-open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.m-toggle.js-open span:nth-child(4) {
  top: 1rem;
  width: 0%;
  left: 50%;
}

.footer {
  padding-top: 8rem;
  padding-bottom: 8rem;
  border-top: 1px solid #dcdcdc;
  font-size: 1.6rem;
  line-height: 2rem;
}
@media (max-width: 767.98px) {
  .footer {
    padding: 4rem 0;
  }
}
.footer .container__bx {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}
@media (max-width: 767.98px) {
  .footer .container__bx {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }
}
.footer__cl--left a {
  text-decoration: none;
  color: #000;
}
.footer__cl--center {
  display: flex;
  justify-content: center;
  padding-top: 6rem;
}
@media (max-width: 767.98px) {
  .footer__cl--center {
    justify-content: flex-start;
    padding-top: 4rem;
  }
}
.footer__cl--right {
  display: flex;
  justify-content: flex-end;
  padding-top: 6rem;
  text-align: right;
}
@media (max-width: 767.98px) {
  .footer__cl--right {
    justify-content: flex-start;
    text-align: left;
    padding-top: 4rem;
  }
}
.footer__cl--right .menu a {
  text-decoration: none;
  color: #000;
}
.footer .logo img {
  max-width: 24.3rem;
}
.footer__add .footer__icos {
  padding-top: 1.5rem;
  background-position: center left;
  background-repeat: no-repeat;
  padding-left: 4rem;
  background-size: 2.4rem;
}
.footer__add .footer__icos:nth-child(1) {
  background-image: url("../imgs/icons/icon-ubicacion.svg");
}
.footer__add .footer__icos:nth-child(2) {
  background-image: url("../imgs/icons/icon-contacto.svg");
}
.footer__add .footer__icos:nth-child(3) {
  background-image: url("../imgs/icons/icon-correo.svg");
}

.rrss {
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 600;
}
.rrss__list {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  justify-content: center;
}
.rrss__list img {
  max-width: 3.2rem;
}

.form-group {
  display: flex;
  flex-flow: column;
  width: 100%;
}
.form-group--inline {
  display: inline-flex;
  margin-bottom: 2rem;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
}
.form-group--inline .form-label {
  width: auto;
  display: inline-flex;
  margin-right: 2.8rem;
}
.form-group--inline .form-control {
  width: 8rem;
  display: inline-flex;
  margin: 0;
  background-color: #f4f4f4;
  text-align: center;
}

.form-label {
  display: flex;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-p {
  font-weight: 500;
  margin-bottom: 2rem;
}
.form-p--result {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
}

.form-control {
  width: 100%;
  border: 0.25px solid #393939;
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  font-family: "Raleway", sans-serif;
  color: #393939;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 2rem;
  opacity: 1;
  box-sizing: border-box;
}
.form-control::placeholder {
  font-family: "Raleway", sans-serif;
  color: #393939;
  font-size: 1.6rem;
  line-height: 2.4rem;
  opacity: 0.6;
}

.form-group-check {
  display: block;
  margin-bottom: 2rem;
  position: relative;
}
.form-group-check--cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.form-group-check input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  cursor: pointer;
  position: absolute;
  z-index: -1;
  width: 1px;
  height: 1px;
  top: 1.8rem;
  left: 0.1rem;
  margin: 0;
}
.form-group-check input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #000;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.form-group-check label {
  position: relative;
  cursor: pointer;
  padding-left: 4.2rem;
  display: flow;
  padding-top: 0.2rem;
  min-height: 2.3rem;
}
.form-group-check label span {
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.form-group-check label a {
  color: #393939;
  text-decoration: none;
}
.form-group-check label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 0.1rem solid #000;
  box-shadow: none;
  padding: 0;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  box-sizing: border-box;
  width: 2.3rem;
  height: 2.3rem;
  background-color: #f4f4f4;
  border-radius: 0.2rem;
  position: absolute;
  left: 0;
  top: 0;
}

.alert {
  background-color: #f4f4f4;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-style: italic;
  margin-bottom: 2rem;
}

.btn {
  background: linear-gradient(175.61deg, #FCEA10 -0.64%, #2FAC66 29.82%, #1D7AB8 117.5%);
  border: 0;
  border-radius: 0.5rem;
  height: 4rem;
  box-sizing: border-box;
  color: white;
  font-family: "Raleway", sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 300ms linear;
}
.btn:hover {
  background: linear-gradient(180deg, #FCEA10 -0.64%, #2FAC66 29.82%, #1D7AB8 117.5%);
}