html {
  /* 1rem = 10px */
  font-size: 62.5%;
}

body{
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: $font; 
  font-weight: 400;
}

.container{
  max-width: 116rem;
  width: 100%;
  margin: 0 auto;
  padding: 0rem;

  &__bx{
    padding: 0 2rem;
    display: flex;
    flex-flow: column;
    // flex-wrap: wrap;

    @media(max-width: $md){
      flex-flow: column;
    }
  }
}

a, button, input{
  outline: none;
}

strong{
  font-weight: 600;
}

.page-content{
  margin-top: 12rem;

  p{
    margin-bottom: 1.6rem;
  }
}


.h2-title {
  position: relative;
  text-align: center;
  margin-bottom: 3rem;
  @media (max-width: $md){
    padding-bottom: 1rem;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    z-index: 1;
    background-color: #2fac66;
    @media (max-width: $md){
      top: inherit;
      bottom: 0;
      left: 50%;
      margin-left: -4rem;
      width: 8rem;
    }
  }
  & .h2 {
    position: relative;
    display: inline-block;
    padding-right: 4rem;
    padding-left: 4rem;
    margin-right: 14rem;
    margin-left: 14rem;
    z-index: 2;
    font-size: 38.4px;
    line-height: 44px;
    background-color: $white;
    @media (max-width: $md){
      padding-right: 0;
      padding-left: 0;
      margin-right: 0;
      margin-left: 0;
      font-size: 22.4px;
      line-height: 27.2px
    }
  }
}

.mdl-contact{
  padding: 0;

  .step{
    padding-top: 10rem;

    @media (max-width: $md){
      padding-top: 5rem;
    }
  }

  &__feat{
    padding: 5rem 0;
    background-color: #f4f4f4;

    @media (max-width: $md){
      padding: 5rem 0 3rem;
    }

    .h2-title .h2{
      background-color: #f4f4f4;

    }
  }

  form{
    padding: 5rem 0;
    max-width: 50rem;
    margin: 0 auto;
    width: 100%;

    @media (max-width: $md){
      padding-top: 0rem;
    }

  }

  p{
    &.small{
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 2rem;
    }
  }

  ul{
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media(max-width: $md){
      grid-template-columns: repeat(1, 1fr);
    }

    li{
      padding-left: 2rem;
      position: relative;
      margin-bottom: 2.3rem;
      &::before{
        content: '';
        width: 1rem;
        height: 1rem;
        background: #2EAA68;
        display: block;
        border-radius: 5rem;
        position: absolute;
        left: 0;
        top: 4px;
      }
    }
  }
}

.mdl-feedback{

  &__icon{
    img{
      max-width: 11.8rem;
    }
  }

  &__txt{
    max-width: 78rem;

    @media(max-width: $md){
      max-width: 42rem;
    }

    h2{
      font-size: 4rem;
      font-weight: 500;
      line-height: 4.7rem;
      text-align: center;
      color: $white;
      font-family: $font2;
      margin-bottom: 4rem;

      @media(max-width: $md){
        font-size: 3rem;
        line-height: 3.6rem;
      }
    }

    p{
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 3rem;
      text-align: center;
      color: $white;

      @media(max-width: $md){
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }

  &__opt{
    padding: 10rem 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media(max-width: $md){
      padding: 6rem 2rem;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -0.1rem;
      left: 0;
      width: 100%;
      height: 2rem;
      background: $white;
      clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%);

      
    }

    &--ko{
      background-color: #E94F4F;
    }

    &--ok{
      background: #2EAA68;
    }

    &--intro{
      height: 65.9rem;
      box-sizing: border-box;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      background-image: url('../imgs/home/hero.jpg');

      @media(max-width: $md){
        background-image: url('../imgs/home/hero-bg-mob.jpg');
        padding: 4rem 0rem;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        height: 60rem;
      }

      
      .mdl-feedback{
        
        &__txt {
          max-width: 112rem;
          width: 100%;
          padding: 0 2rem;
          text-align: right;
          display: flex;
          flex-flow: column;
          justify-content: flex-end;
          align-items: flex-end;
          box-sizing: border-box;

          @media(max-width: $md){
            max-width: 40rem;
            align-items: flex-start;
          }
    

          h1{
            font-size: 3.4rem;
            font-weight: 400;
            line-height: 4rem;
            text-align: left;
            color: $white;
            margin-bottom: 3rem;
            max-width: 50rem;

            strong{
              font-weight: 600;
            }
          }

          img{
            max-width: 3.2rem;
          }
        }
      }
    }
  }

}

.pop{
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-left: 1rem;

  img{
    display: block;
  }
}

.fancy{
  &.fancybox{
    &__content{
      padding: 5rem;

      @media(max-width: $md){
        padding: 2rem;
      }

      .h2-title .h2 {
        margin-right: 10rem;
        margin-left: 10rem;

        @media(max-width: $md){
          margin-left: 0;
          margin-right: 0;
        }
      }

      p{
        text-align: center;
        margin-bottom: 3rem;
      }

      img{
        width: 100%;
        max-width: 40rem;
        margin: 0 auto;
      }
    }
  }
}